import React from 'react';

const LeaveToolbar = ({
                          leaveTypes,
                          finYears,
                          selectedLeaveType,
                          selectedFinYear,
                          onFilterChange,
                          onTabChange,
                          onConsensusClick,
                          activeTab,
                          showAllLeaveTypesOption
                      }) => {
    const handleLeaveTypeChange = (e) => {
        onFilterChange(e.target.value, selectedFinYear);
    };

    const handleFinYearChange = (e) => {
        onFilterChange(selectedLeaveType, e.target.value);
    };

    return (
        <div className="row mb-3 align-items-center">
            <div className="col-3">
                <select
                    value={selectedLeaveType || ''}
                    onChange={handleLeaveTypeChange}
                    className="form-control"
                >
                    {showAllLeaveTypesOption && <option value="">Tüm İzin Tipleri</option>}
                    {leaveTypes.map(lt => (
                        <option key={lt.id} value={lt.id}>{lt.title}</option>
                    ))}
                </select>
            </div>
            <div className="col-3">
                <select
                    value={selectedFinYear}
                    onChange={handleFinYearChange}
                    className="form-control"
                >
                    {finYears.map(year => (
                        <option key={year} value={year}>{year}</option>
                    ))}
                </select>
            </div>
            <div className="col-6 d-flex justify-content-between align-items-center">
                <ul className="nav nav-pills">
                    <li className="nav-item">
                        <a
                            className={`nav-link ${activeTab === 'summary' ? 'active' : ''}`}
                            onClick={(e) => { e.preventDefault(); onTabChange('summary'); }}
                            href="#"
                        >
                            Özet
                        </a>
                    </li>
                    <li className="nav-item">
                        <a
                            className={`nav-link ${activeTab === 'leaves' ? 'active' : ''}`}
                            onClick={(e) => { e.preventDefault(); onTabChange('leaves'); }}
                            href="#"
                        >
                            İzinler
                        </a>
                    </li>
                </ul>
                {activeTab === 'leaves' && (
                    <a
                        className="nav-link"
                        onClick={(e) => { e.preventDefault(); onConsensusClick(); }}
                        href="#"
                    >
                        Mutabakat
                    </a>
                )}
            </div>
        </div>
    );
};

export default LeaveToolbar;
